/**
   *@description: this file defines the variables we will use to define our responsive behavior
**/
/**
   *@description: this file is in charge of assigning the values to the z-index keyword
   *@example: we can find an example on sgcb-store modal
**/
/**
   *@description: color-map
**/
html,
body {
  background: #f5f9fb;
  overflow: hidden;
  display: block;
  height: 100%;
  width: 100%;
  font-family: "scib-txt-regular";
}