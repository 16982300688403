@use 'settings';

html,
body {
	background: #f5f9fb;
	overflow: hidden;
	display: block;
	height: 100%;
	width: 100%;
	font-family: settings.$font-regular-txt;
}
